import { Controller } from '@hotwired/stimulus';

const propFields = [['trade_wager_descr', 'descr'], ['trade_wager_amt', 'amt'], ['trade_condition_a', 'cond_a'], ['trade_condition_b', 'cond_b'], ['trade_allow_tie', 'allow_tie']];
const strikeFields = [['trade_wager_descr', 'descr']];

export default class TradeFormController extends Controller {
    static targets = ['name'];
    static values = {
        existingWagers: Object
    }

    initialize () {
        console.log('TradeFormController.initialize()');
    }

    connect () {
        console.log('TradeFormController.connect()', this.element.dataset);
        this.clearNumericInputs();

        const tradeType = this.element.dataset.tradeType
        switch (tradeType) {
            case 'prop':
                this.setupNewWagerScreen(propFields);
                break;
            case 'strike':
                this.setupNewWagerScreen(strikeFields);
                break;
            case 'survivor':
                // noop
                break;
            default:
                console.log('unknown trade type', tradeType)

        }
    }

    clearNumericInputs () {
        new Array(...this.element.querySelectorAll('input[type=number]')).forEach((input) => {
            input.addEventListener('focus', () => {
                // console.log('input.focus()', input);
                const currentVal = input.value;
                if (parseInt(currentVal) === 0) {
                    input.value = '';
                }
            });
        });
    }

    setupNewWagerScreen (field_ids) {
        console.log('TradeFormController.setupNewWagerScreen.field_ids', field_ids, this.existingWagersValue);

        function toggleRadios (selector, value) {
            // console.log('toggleRadios', selector, value);
            $(selector).attr('disabled', value);
        }

        function setValues (obj, sourceEl) {
            if (!obj) {
                obj = {};
            }
            // console.log("setting values", obj, sourceEl);
            field_ids.forEach(function (fieldPair) {
                var fieldId = fieldPair[0];
                var objKey = fieldPair[1];
                var field = document.getElementById(fieldId);
                // console.log('toggling', fieldId, objKey, obj[objKey], field.type, obj, field);
                if (obj[objKey]) {
                    if (field.type === 'checkbox') {
                        // console.log('toggling checkbox', !!obj[objKey])
                        if (obj[objKey] == '1') {
                            field.checked = true;
                            field.value = '1';
                        } else {
                            field.checked = false;
                            field.value = '1';
                        }
                    } else if (field.type === 'radio') {
                        // console.log('toggling radio', !!obj[objKey]);
                        toggleRadios($(field).attr('name'), false);
                    } else {
                        field.value = obj[objKey];
                    }
                    field.setAttribute('disabled', 'disabled');
                } else {
                    if (field.type === 'checkbox') {
                        field.checked = false;
                        field.value = '1';
                    } else if (field.type === 'radio') {
                        toggleRadios($(field).attr('name'), true);
                    } else {
                        field.value = '';
                    }

                    if (sourceEl.value) {
                        field.removeAttribute('disabled');
                    } else {
                        field.setAttribute('disabled', 'disabled');
                    }
                }
            });
        }

        var $form = document.querySelector('.wager__form');
        // console.log('window.load (trade/new_prop)');

        var $teamMenu = document.querySelector('#trade_team_id');
        if (this.existingWagersValue.length === 0) {
            $teamMenu.value = '_new';
        }

        $teamMenu.addEventListener('change',  (evt) => {
            // console.log('change', evt.target.value);

            var team = this.existingWagersValue[evt.target.value];
            setValues(team, $teamMenu);

            if (evt.target.value === '_new') {
                $form.setAttribute('data-wager-type', 'new');
            } else {
                $form.setAttribute('data-wager-type', 'existing');
            }
        });
    };

}