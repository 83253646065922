import { Controller } from '@hotwired/stimulus';

export default class LoadingController extends Controller {
    static values = {
        retry: { type: Boolean, default: true },
        maxTries: { type: Number, default: 5 },
        interval: { type: Number, default: 5 }
    };

    initialize () {
        console.log('LoadingController.initialize', this.element, this.retryValue, this.maxTriesValue, this.intervalValue);
        this.retryCount = 0;
    }

    connect () {
        console.log('LoadingController.connect', this.retryCount, this.maxTriesValue);

        // if (this.retryCount < this.maxTriesValue) {
        //     this.retryCount += 1;
        //     window.location.reload(true);
        // }
    }
}