// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
// console.log('Vite ⚡️ Rails');

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

// console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails');

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

import jQuery from 'jquery';
import { Foundation } from 'foundation-sites';

// import '@hotwired/turbo-rails';
import * as Turbo from '@hotwired/turbo';
import { Application } from '@hotwired/stimulus';
import '@client-side-validations/client-side-validations/src';
import '@client-side-validations/simple-form/src';
import jstz from 'jstz';
import TradeFormController from '../controllers/trade_form_controller';
import DatePickerController from '../controllers/date_picker_controller';
import TradeListController from '../controllers/trade_list_controller';
import GlobalController from '../controllers/global_controller';
import WhatIfController from '../controllers/what_if_controller';
import PoolController from '../controllers/pool_controller';
import SidebarController from '../controllers/sidebar_controller';
import LoadingController from '../controllers/loading_controller';

window.$ = window.jQuery = jQuery;
Foundation.addToJquery(window.$);

window.BB = window.BB || {};

// console.log('starting turbo');
Turbo.start();

// console.log('registering stimulus controllers');
window.Stimulus = Application.start();
Stimulus.register('global', GlobalController);
Stimulus.register('trade-form', TradeFormController);
Stimulus.register('date-picker', DatePickerController);
Stimulus.register('trade-list', TradeListController);
Stimulus.register('what-if', WhatIfController);
Stimulus.register('pool', PoolController);
Stimulus.register('sidebar', SidebarController);
Stimulus.register('loading', LoadingController);

// console.log('application.js');

// source: https://stackoverflow.com/a/71974422/56545
// console.log('[disabling turbo forms]')
// document.querySelectorAll('form').forEach(function (el) {
//     // console.log('form turbo', el.dataset.turbo, el.dataset.turbo == undefined)
//     if (el.dataset.turbo == undefined) {
//         el.dataset.turbo = false;
//     }
// });
