import { Controller } from '@hotwired/stimulus';

export default class WhatIfController extends Controller {
    static values = {
        type: { type: String, default: 'date' }
    };

    connect () {
        // console.log('WhatIfController.connect');
        setTimeout(function () {
            window.$('.column--results .callout').fadeOut();
        }, 4000);
    }

    toggle (evt) {
        evt.preventDefault();
        $(evt.currentTarget).closest('tr').next('tr').toggleClass('--open');
    }

    reset (evt) {
        // console.log('resetting form');
        evt.preventDefault();
        $('#what_if_scenario_hypo_trade_attributes_quantity').val('');
        $('#what_if_scenario_hypo_trade_attributes_price').val('');
        $('#what_if_scenario_hypo_trade_attributes_team_id').val('');
        $('.what_if_scenario_hypo_trade_dir input[value="0"]').prop('checked', true);
    }
}