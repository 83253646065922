import { Controller } from '@hotwired/stimulus';
import DataTable from 'datatables.net-dt';

window.$.fn.DataTable = window.dt = DataTable;

export default class TradeListController extends Controller {
    static values = {
        type: { type: String, default: 'date' }
    };

    initialize () {
        // console.log('TradeListController.initialize', this.element);
        new DataTable(); // no-op because DT seems to balk at first instance when used via Stim
    }

    connect () {
        this.configDataTable();
    }

    configDataTable () {
        if (this.dataTable) {
            // console.log('TradeListController.configDataTable(false)', this.element.id, this.element.dataset, this);
            return;
        }

        // console.log('TradeListController.configDataTable(true)', this.element.id, this.element.dataset, this);

        const menu = (this.element.dataset.longTable === 'true') ? [[20, 50, -1], [20, 50, 'All']] : [[5, 20, -1], [5, 20, 'All']];

        let pageLength = parseInt(this.element.dataset.pageLength);
        if (!pageLength) {
            pageLength = menu[0][0];
        }
        let pagingType = this.element.dataset.pagingType;
        if (!pagingType) {
            pagingType = 'simple_numbers';
        }
        const dtConfig = {
            destroy: true,
            pageLength,
            paging: this.element.dataset.paging !== 'false',
            pagingType,
            searching: this.element.dataset.search === 'true',
            lengthMenu: menu,
            lengthChange: this.element.dataset.changeLength === 'true',
            language: {
                info: '_START_ to _END_ of _TOTAL_ entries',
                lengthMenu: 'Show _MENU_'
            },
            responsive: false
        };
        // console.log('dtConfig', dtConfig);

        this.dataTable = window.$(this.element).DataTable(dtConfig);
    }
}