import { Controller } from '@hotwired/stimulus';
import jQuery from 'jquery';
import { Foundation } from 'foundation-sites';
import * as timeago from 'timeago.js';

export default class SidebarController extends Controller {
    static values = {
        type: { type: String, default: 'date' }
    };

    connect () {
        // console.log('SidebarController.connect');

        window.$('.group-list > a').trigger('click');
        window.$('[data-close-on-click]').on('click', function () {
            window.$('#offCanvasMenu').foundation('close');
        });
    }
}