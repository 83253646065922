import { Controller } from '@hotwired/stimulus';
import jQuery from 'jquery';
import { Foundation } from 'foundation-sites';
import * as timeago from 'timeago.js';
import jstz from 'jstz';

window.BB = window.BB || {};

export default class GlobalController extends Controller {
    static values = {
        type: { type: String, default: 'date' }
    };

    connect () {
        // console.log('GlobalController.connect');
        window.$ = window.jQuery = jQuery;
        Foundation.addToJquery(window.$);
        this.initFoundation();
        $(window).on('changed.zf.mediaquery', this.initFoundation);

        const time_nodes = document.querySelectorAll('time.timeago');
        if (time_nodes.length) {
            timeago.render(time_nodes);
        }

        window.$('a.external').attr('target', '_blank');

        window.BB.jstz = jstz;

        window.addEventListener('message', this.handleMessage)
    }

    disconnect () {
        // console.log('GlobalController.disconnect');
        $(window).off('changed.zf.mediaquery', this.initFoundation);
    }

    initFoundation () {
        // console.log('initFoundation');
        $(document).foundation();
    }

    handleMessage = (args) => {
        // console.log('handleMessage', args)
        // console.log('handleMessage.BetoJsBridge', window.BetoJsBridge)
        if (window.BetoJsBridge) {
            console.log('got a bridge back to the app!')
        }
    }

    preventDefault (evt) {
        // console.log('global.preventDefault');
        evt.preventDefault();
    }

    stopPropagation (evt) {
        // console.log('global.stopPropagation');
        evt.stopPropagation();
    }

    inlineFormSubmit (evt) {
        // console.log('global.inlineFormSubmit');
        this.preventDefault(evt);
        const form = evt.target.closest('form');

        window.fetch(form.action, { method: form.method, body: new FormData(form) })
            .then((response) => {
                // console.log('response', response);
                if (/json/.test(response.headers.get('Content-Type'))) {
                    return response.json();
                }
                return response.text();
            })
            .then((body) => {
                console.log('form submitted, response:', body);
            });
    }
}