import { Controller } from '@hotwired/stimulus';
import jQuery from 'jquery';
import { Foundation } from 'foundation-sites';
import * as timeago from 'timeago.js';

export default class PoolController extends Controller {
    static values = {
        type: { type: String, default: 'date' }
    };

    connect () {
        // console.log('PoolController.connect');
    }

    toggleRules(evt) {
        evt.preventDefault();
        var $rules = window.$('.rules--long');
        // var $a = window.$('#toggle-rules');
        // $a.click(function () {
            $rules.toggleClass('is-expanded');
        //     return false;
        // });
    }
}