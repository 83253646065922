import { Controller } from '@hotwired/stimulus';
import flatpickr from 'flatpickr';

const fpDateTimeConfig = {
    allowInput: true,
    altInput: true,
    enableTime: true,
    altFormat: 'M j Y h:i K',
    ariaDateFormat: 'M j Y h:i K',
    dateFormat: 'Y-m-d H:i K',
    // allowInvalidPreload: true,
    // onReady: (_, _2, instance) => {
    //     console.log('flatpickr onReady', instance.calendarContainer.classList, instance)
    //     instance.calendarContainer.classList.add('showTimeInput')
    // }
};

const fpDateConfig = {
    allowInput: true,
    altInput: true,
    altFormat: 'M j Y',
    ariaDateFormat: 'M j Y',
    dateFormat: 'Y-m-d',
};

const fpShortDateConfig = {
    allowInput: true,
    altInput: true,
    altFormat: 'M j',
    ariaDateFormat: 'M j Y',
    dateFormat: 'Y-m-d',
};

export default class DatePickerController extends Controller {
    static values = {
        type: { type: String, default: 'date' }
    };

    // initialize () {
    //     console.log('DatePickerController.initialize()', { type: this.typeValue });
    // }

    connect () {
        const useDateTime = this.typeValue === 'datetime';
        const useShortDate = this.typeValue === 'date-short';
        const config = useDateTime ? fpDateTimeConfig : useShortDate ? fpShortDateConfig : fpDateConfig;
        // console.log('DatePickerController.connect()', this.typeValue, config);
        flatpickr(this.element, config);
    }
}